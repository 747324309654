/* eslint-disable no-console */
import { DocumentNode, OperationDefinitionNode, parse } from 'graphql'
import { ClientError } from 'graphql-request'
import * as R from 'remeda'
import * as Sentry from '@sentry/remix'

class GraphQLAPIError extends Error {}

export type SuccessResult<T> = T extends { result?: infer U }
  ? Exclude<U, null | undefined>
  : T

export async function processRequestData<
  T extends object,
  R = SuccessResult<T>
>(data: T): Promise<R> {
  const keys = Object.keys(data) as (keyof T)[]

  if (keys.length === 1) {
    const key = keys[0]!
    const value = data[key]

    if (R.isObjectType(value) && R.isString(key)) {
      if (
        'reasons' in value &&
        Array.isArray(value.reasons) &&
        value.reasons.length > 0
      ) {
        Sentry.withScope((scope) => {
          scope.setTag('operation', key)
          scope.setContext('response', { reasons: value.reasons })
          Sentry.captureException(
            new GraphQLAPIError(`Failed GraphQL query ${key}`)
          )
        })

        return Promise.reject(value.reasons)
      }

      if ('result' in value) {
        return value.result as R
      }
    }
  }

  return data as unknown as R
}

export const getOperationName = (document: string): string | null => {
  const parsedDocument: DocumentNode = parse(document)

  // Find the first OperationDefinition node
  const operationDefinition = parsedDocument.definitions.find((definition) => {
    return definition.kind === 'OperationDefinition'
  }) as OperationDefinitionNode | undefined

  return operationDefinition?.name?.value || null
}

export const trackErrorInSentry = (error: unknown) => {
  Sentry.withScope((scope) => {
    if (error instanceof ClientError) {
      scope.setTag('operation', getOperationName(error.request.query as string))
    }

    Sentry.captureException(error)
  })
}
