import { gql } from 'graphql-request'

export const fetchOpenBookingSlotsDocument = gql`
  query fetchOpenBookingSlots {
    fetchOpenBookingSlots {
      result {
        date
        bookingSlots {
          id
          type
          startDate
          endDate
          expiredAt
          status
        }
      }
      reasons
    }
  }
`

export const fetchBookingSlotByIdDocument = gql`
  query fetchBookingSlotById($bookingSlotId: ID!) {
    fetchBookingSlotById(bookingSlotId: $bookingSlotId) {
      result {
        id
        type
        startDate
        endDate
        expiredAt
        status
      }
      reasons
    }
  }
`

export const fetchMemberProgramDocument = gql`
  query fetchMemberProgram {
    fetchMemberProgram {
      result {
        programStatus
      }
      reasons
    }
  }
`

export const fetchLegalDocumentsWhichNeedAgreementDocument = gql`
  query fetchLegalDocumentsWhichNeedAgreement {
    fetchLegalDocumentsWhichNeedAgreement {
      result {
        id
        version
        choices {
          id
          type
          isMandatory
        }
      }
      reasons
    }
  }
`
