import { gql } from 'graphql-request'

export const createPaymentTransactionDocument = gql`
  mutation createPaymentTransaction($bookingSlotId: ID) {
    createPaymentTransaction(
      paymentTunnelType: website
      bookingSlotId: $bookingSlotId
    ) {
      result {
        clientSecret
        paymentProviderPublicKey
      }
      reasons
    }
  }
`

export const attachInfosToPaymentTunnelDocument = gql`
  mutation attachInfosToPaymentTunnel(
    $clientSecret: ID!
    $buyerInfos: BuyerInfos!
  ) {
    attachInfosToPaymentTunnel(
      clientSecret: $clientSecret
      buyerInfos: $buyerInfos
    ) {
      result {
        id
      }
      reasons
    }
  }
`

export const agreeLegalDocumentDocument = gql`
  mutation agreeLegalDocument($id: String!, $version: Float!) {
    agreeLegalDocument(id: $id, version: $version) {
      result
      reasons
    }
  }
`

export const submitWebsiteInterestedCompanyFormDocument = gql`
  mutation submitWebsiteInterestedCompanyForm(
    $formId: ID!
    $portalId: ID!
    $fields: WebsiteInterestedCompanyFormFields!
  ) {
    submitWebsiteInterestedCompanyForm(
      formId: $formId
      portalId: $portalId
      fields: $fields
    ) {
      result
      reasons
    }
  }
`

export const submitWebsiteContactFormDocument = gql`
  mutation submitWebsiteContactForm(
    $formId: ID!
    $portalId: ID!
    $fields: WebsiteContactFormFields!
  ) {
    submitWebsiteContactForm(
      formId: $formId
      portalId: $portalId
      fields: $fields
    ) {
      result
      reasons
    }
  }
`
